<template>
    <div>
        <div style="border: 1px solid #ccc;">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 500px; overflow-y: hidden;"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
        </div>
    </div>
  </template>
<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default Vue.extend({
  components: { Editor, Toolbar },
  data () {
    return {
      editor: null,
      html: '<p>hello</p>',
      toolbarConfig: { },
      editorConfig: {
        placeholder: '请输入内容...',
        readOnly: false, // 只读、不可编辑
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            fieldName: 'file',
            server: `${this.$Upload}/api/Laboratory/File_user_fuupload/`,
            meta: {
              Numbers: '123123',
              Type: 2,
              User: this.$store.state.Login.User,
              Username: this.$store.state.Login.Username,
              Factory: this.$store.state.Login.Factory
            }
          },
          uploadVideo: {
            fieldName: 'file',
            server: `${this.$Upload}/api/Laboratory/File_user_fuuploadvideo/`,
            meta: {
              Numbers: '123123',
              Type: 2,
              User: this.$store.state.Login.User,
              Username: this.$store.state.Login.Username,
              Factory: this.$store.state.Login.Factory
            },
            // 单个文件的最大体积限制，默认为 10M
            maxFileSize: 500 * 1024 * 1024, // 5M
            // 最多可上传几个文件，默认为 5
            maxNumberOfFiles: 10
          }
        }
      },
      mode: 'default' // or 'simple'
    }
  },
  methods: {
    onCreated (editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    }
  },
  mounted () {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    }, 1500)
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
})
</script>
